import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { format, formatDistanceToNowStrict, isValid } from "date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import { getPublisherName, getPublisherTags, getImageSourceList } from "../utils/utils.js";
import ConfirmPopup from "./utils/confirmPopup";
import CategoryLinking from "../components/modals/categoryLinking";
import { allowedBestOfWebCategoryNames } from "../components/utils/allowLists";
import { ReactComponent as WinnerRibbonSVG } from "../svg/winner-logo.svg";

import "./article.css";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const CUSTOM_TAG_MAX_CHARACTERS = 13;

function Article(props) {
    const alert = useAlert();
    const [formData, setFormData] = useState(() => ({
        expires_at: props.article.expires_at ? format(new Date(props.article.expires_at), "yyyy-MM-dd'T'hh:mm") : "",
        custom_tag: props.article.custom_tag ? props.article.custom_tag : ""
    }));
    const [modalIsOpen, setIsOpen] = useState(false);
    const imageList = useMemo(() => getImageSourceList(props.article.full_info), [props.article.full_info]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [noImage, setNoImage] = useState(!imageList.length);
    const [isLoadingImg, setIsLoadingImg] = useState(!noImage);
    const [currentImage, setCurrentImage] = useState(() => imageList[selectedImageIndex]);
    const [openCategoryLinking, setOpenCategoryLinking] = useState(false);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onInputChange = useCallback(e => {
        setFormData(currentFormData => ({ ...currentFormData, [e.target.name]: e.target.value }));
    }, []);

    const resetExpiration = useCallback(() => {
        setFormData(currentFormData => ({ ...currentFormData, expires_at: "" }));
    }, []);

    const onImageError = useCallback(
        e => {
            if (imageList.length > selectedImageIndex + 1) {
                e.target.onerror = null;
                e.target.src = imageList[selectedImageIndex + 1];
                setSelectedImageIndex(selectedImageIndex + 1);
                setCurrentImage(imageList[selectedImageIndex + 1]);
            } else {
                setNoImage(true);
                setCurrentImage(null);
            }
        },
        [imageList, selectedImageIndex]
    );

    const isValidCustomTag = useCallback(() => {
        if (props.isSatire || !formData.custom_tag || formData.custom_tag.length <= CUSTOM_TAG_MAX_CHARACTERS) return true;
        return false;
    }, [formData.custom_tag, props.isSatire]);

    const setTopicImage = useCallback(() => {
        if (!currentImage) {
            alert.show("Hmm... can't find the image", {
                timeout: 1000,
                type: "error"
            });
        }
        props.setTopicImage(currentImage);
    }, [alert, currentImage, props]);

    const onSave = useCallback(
        (data, parentId) => {
            props.onUpdate(null, data, parentId, "article");
            setOpenCategoryLinking(false);
        },
        [props]
    );

    const onPositionUpdate = useCallback(() => {
        props.onPositionUpdate(props.article.id);
    }, [props]);

    const pinArticle = useCallback(() => {
        props.onPin({ url: props.article.full_info.url, article_id: props.article.full_info.id });
    }, [props]);

    const isExpired = useMemo(() => (props.article.expires_at ? new Date(props.article.expires_at) < Date.now() : null), [props.article.expires_at]);

    const datePublished = useMemo(
        () => (isValid(new Date(props.article.full_info.datePublished)) ? new Date(props.article.full_info.datePublished) : null),
        [props.article.full_info.datePublished]
    );

    const datePublishedTitle = useMemo(() => (datePublished ? datePublished.toString() : "Invalid date"), [datePublished]);

    if (props.isPinned && !props.article.full_info) {
        return (
            <div className="article card mb-2">
                <div className="article-header"></div>
                <div className="article-title card-body py-2">
                    <a href={props.article.url} target="_blank" rel="noopener noreferrer">
                        <p className="card-text text-primary">{props.article.url}</p>
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className={`article card mb-2 ${props.isSatire ? "satire" : getPublisherTags(props.article.full_info)}`} data-testid="pinned-article">
            {openCategoryLinking && (
                <CategoryLinking
                    isOpen={true}
                    onClose={() => setOpenCategoryLinking(false)}
                    onSave={onSave}
                    parentId={props.parentId}
                    data={props.parentData}
                />
            )}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Article settings">
                <div className="modal-body">
                    <div className="form-group has-danger">
                        <label className="control-label" htmlFor="custom_tag">
                            Category name:
                        </label>
                        <select
                            onChange={onInputChange}
                            className="custom-select d-block mb-2"
                            defaultValue={formData.custom_tag}
                            name="custom_tag"
                            id="custom_tag"
                        >
                            <option value="">Select one</option>
                            {allowedBestOfWebCategoryNames.map((categoryName, i) => (
                                <option key={i} value={categoryName}>
                                    {categoryName}
                                </option>
                            ))}
                        </select>
                        <div className="invalid-feedback">{`Max characters allowed: (${CUSTOM_TAG_MAX_CHARACTERS})`}</div>

                        <label className="control-label mt-3" htmlFor="disabledInput">
                            Choose date and time when the article should be unpinned:
                        </label>
                        <input
                            onChange={onInputChange}
                            className="form-control"
                            name="expires_at"
                            type="datetime-local"
                            value={formData.expires_at}
                            placeholder="Choose date and time"
                        />
                        {formData.expires_at ? (
                            <span onClick={resetExpiration} className="float-right pr-1 pointer text-info">
                                Clear
                            </span>
                        ) : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={closeModal} className="btn btn-secondary">
                        Close
                    </button>
                    <button
                        className="btn btn-primary"
                        disabled={!isValidCustomTag()}
                        onClick={() => {
                            // do not allow empty category name (articles under 'Make me laugh' only)
                            if (props.isSatire && !formData.custom_tag) {
                                alert.show("<Category name> is required");
                                return;
                            }
                            props.onUpdate(props.article._id, formData, props.parentId, "article");
                            closeModal();
                        }}
                    >
                        Save changes
                    </button>
                </div>
            </Modal>
            <div className="article-header">
                <span
                    title={getPublisherName(props.article.full_info)}
                    className="publisher-name float-left text-muted text-truncate"
                    data-testid="publisher-name"
                >
                    {getPublisherName(props.article.full_info)}
                </span>
                {props.isPreview && !props.article.full_info.isPinned && !props.isBreakingNews && (
                    <ConfirmPopup title="Pin this article?" onConfirm={pinArticle}>
                        <span title="Pin this article" style={{ marginRight: "0.75em" }} className="pointer" data-testid="pin-article-button">
                            <i className="fas fa-thumbtack"></i>
                        </span>
                    </ConfirmPopup>
                )}
                <>
                    {currentImage && (props.isPinned || props.isPreview) && !props.isBreakingNews && (
                        <ConfirmPopup title="Set this image as a default image of the story" onConfirm={setTopicImage}>
                            <span title="Set this image as default image of the story" style={{ marginRight: "0.75em" }} className="pointer">
                                <i className="fas fa-link"></i>
                            </span>
                        </ConfirmPopup>
                    )}
                    <CopyToClipboard
                        className="float-right pointer"
                        text={`${props.article.full_info.url}?freespoke_id=${props.article.full_info.id}`}
                        onCopy={() => {
                            alert.show("copied", { timeout: 1000, type: "info" });
                        }}
                    >
                        <span>
                            <i className="far fa-copy"></i>
                        </span>
                    </CopyToClipboard>
                </>
            </div>
            <div className="article-image-wrapper" data-testid="article-image">
                <a href={props.article.full_info.url} target="_blank" rel="noopener noreferrer">
                    {/* Custom tags for satire only */}
                    {props.article.custom_tag && props.isSatire ? (
                        <div className="custom-tag-satire p-1" title={props.article.custom_tag}>
                            {props.article.custom_tag}
                        </div>
                    ) : null}
                    {imageList && imageList.length && !noImage ? (
                        <React.Fragment>
                            {isLoadingImg ? (
                                <div className="spinner-border text-primary align-center" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : null}
                            <img
                                style={{ display: isLoadingImg ? "none" : "block" }}
                                className="w-100 align-top"
                                src={currentImage}
                                onLoad={() => {
                                    setIsLoadingImg(false);
                                }}
                                onError={onImageError}
                                alt="Article"
                            />
                        </React.Fragment>
                    ) : (
                        <div className="no-image text-dark">No image</div>
                    )}
                    {props.isSatire ? (
                        <div key="satire" title="satire" className="circle tag-satire d-inline-block float-right ml-2"></div>
                    ) : (
                        getPublisherTags(props.article.full_info).map(tag => {
                            return <div key={tag} title={tag} className={`circle tag-${tag.toLowerCase()} d-inline-block float-right ml-2`}></div>;
                        })
                    )}
                </a>
            </div>
            <div className="article-title card-body py-2">
                {props.isPinned || props.isPreview || props.isBanned || props.isBreakingNews ? (
                    /* Custom tags for non-satire only */
                    <React.Fragment>
                        <small className="text-muted" title={datePublishedTitle} data-testid="date-published">
                            {datePublished ? formatDistanceToNowStrict(datePublished, { addSuffix: true }) : "Invalid date"}
                        </small>
                        {props.article.custom_tag && !props.isSatire ? (
                            <span title={datePublishedTitle} className={`custom-tag-non-satire tag-${getPublisherTags(props.article.full_info)[0]}`}>
                                {props.article.custom_tag}
                            </span>
                        ) : null}
                    </React.Fragment>
                ) : null}
                <a href={props.article.full_info.url} target="_blank" rel="noopener noreferrer">
                    <p className="card-text text-primary" data-testid="article-title">
                        {props.article.full_info.headline || props.article.full_info.title}
                    </p>
                </a>
            </div>
            {!props.isPinned && !props.isPreview && !props.isBanned && !props.isBreakingNews ? (
                <div className="article-footer card-footer border-top-0 text-muted py-1">
                    <small title={datePublishedTitle}>
                        {datePublished ? formatDistanceToNowStrict(datePublished, { addSuffix: true }) : "Invalid date"}
                    </small>
                </div>
            ) : null}
            {props.isPinned || props.article.full_info.isPinned || props.isBreakingNews ? (
                <div className="article-footer card-footer border-top-0 text-muted py-1 px-2">
                    <React.Fragment>
                        {props.showPosition && (
                            <div className="d-inline-block ml-2 item-position-box">
                                <span title="Display position in a single list">{props.itemPosition + 1}</span>
                            </div>
                        )}
                        {props.showPosition && (
                            <ConfirmPopup title="Move this article to position #1 ?" onConfirm={onPositionUpdate}>
                                <span className="winner-ribbon ml-2 float-right pointer">
                                    <WinnerRibbonSVG />
                                </span>
                            </ConfirmPopup>
                        )}
                        {(props.isSatire || props.isBreakingNews) && (
                            <span onClick={openModal} title="Set expiration" className="d-inline-block pointer ml-2">
                                <i className="far fa-edit"></i>
                            </span>
                        )}
                        {props.article.expires_at ? (
                            <span className={`ml-2 ${isExpired ? "text-danger" : ""}`}>
                                {isExpired
                                    ? "Expired"
                                    : `Expires  ${formatDistanceToNowStrict(new Date(props.article.expires_at), { addSuffix: true })}`}
                            </span>
                        ) : null}
                        {props.isPreview && props.article.full_info.isPinned && (
                            <span className="d-inline badge badge-pill badge-warning" data-testid="pinned-article-label">
                                Pinned
                            </span>
                        )}
                        <ConfirmPopup
                            title="Unpin this article?"
                            onConfirm={() => props.onDelete(props.isBreakingNews ? props.parentId : props.article.id)}
                        >
                            <span className="float-right pointer mr-2 ml-auto">
                                <i className="fas fa-trash-alt" data-testid="delete-article-button"></i>
                            </span>
                        </ConfirmPopup>
                    </React.Fragment>
                </div>
            ) : null}
            {props.isPreview && !props.isBeingAddedToBan && !props.isBanned && !props.article.full_info.isPinned ? (
                <div className="article-footer card-footer border-top-0 text-muted py-1 px-2">
                    <ConfirmPopup title="Ban this article?" onConfirm={() => props.addArticleToBanlist(props.storyId, props.article.full_info.id)}>
                        <span title="Add to banlist" className="ban-icon d-inline-block pointer mr-2">
                            <i className="fas fa-ban" data-testid="ban-article-button"></i>
                        </span>
                    </ConfirmPopup>
                </div>
            ) : null}
            {props.isBanned ? (
                <div className="article-footer card-footer border-top-0 text-muted py-1 px-2">
                    <ConfirmPopup title="Unban this article?" onConfirm={() => props.removeArticleFromBanlist(props.storyId, props.article.id)}>
                        <span title="Remove from banlist" className="ban-icon d-inline-block pointer mr-2" data-testid="unban-button">
                            <i className="fas fa-trash-alt"></i>
                        </span>
                    </ConfirmPopup>
                    <span className="text-danger">This article has been banned</span>
                </div>
            ) : null}
        </div>
    );
}

Article.propTypes = {
    storyId: PropTypes.string,
    article: PropTypes.object.isRequired,
    itemPosition: PropTypes.number,
    parentData: PropTypes.object,
    categories: PropTypes.array,
    parentId: PropTypes.string,
    isPinned: PropTypes.bool,
    isBanned: PropTypes.bool,
    isSatire: PropTypes.bool,
    isPreview: PropTypes.bool,
    isBreakingNews: PropTypes.bool,
    showPosition: PropTypes.bool,
    isBeingAddedToBan: PropTypes.bool,
    onDelete: PropTypes.func,
    addArticleToBanlist: PropTypes.func,
    setTopicImage: PropTypes.func,
    removeArticleFromBanlist: PropTypes.func,
    onPin: PropTypes.func,
    onUpdate: PropTypes.func,
    onPositionUpdate: PropTypes.func
};

export default Article;
// export default React.memo(Article, (prevProps, nextProps) => {
//     if (prevProps.article.updatedAt !== nextProps.article.updatedAt) {
//         console.log('prevProps.article.updatedAt: ', prevProps.article.updatedAt)
//         console.log('prevProps.article.updatedAt: ', prevProps.article.updatedAt)
//       return false;
//     }
//     return true;
// });
