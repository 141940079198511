import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { isEmptyObject, toTimeAgo } from "../../utils/utils.js";
import AddOrUpdateSpecialContentModal from "../topic/addOrUpdateSpecialModal";
import ConfirmPopup from "../utils/confirmPopup";
import "./articleTile.css";

function ArticleTile({ article, tag, topicId, deleteArticle, askParentToRefetch }) {
    const alert = useAlert();
    const [isEditMode, setEditMode] = useState(false);

    const openEditModal = useCallback(() => {
        setEditMode(true);
    }, []);

    const getPublisherName = useCallback((result = {}) => {
        if (result.publisherName) {
            return result.publisherName;
        } else if (result.publishers && !isEmptyObject(result.publishers)) {
            let publisher = result.publishers[Object.keys(result.publishers)[0]].name;
            return publisher;
        }
    }, []);

    const onDeleteClick = useCallback(() => {
        deleteArticle(article.id);
    }, [article.id, deleteArticle]);

    const onModalClose = useCallback(() => setEditMode(false), []);

    const onUpdateSuccess = useCallback(() => {
        onModalClose();
        askParentToRefetch();
    }, [askParentToRefetch, onModalClose]);

    return (
        <div key={article.id} className={`article-content card mb-2 mr-2 ${!article.active ? "border-danger" : ""}`}>
            <div className="article-header">
                <span title={getPublisherName(article)} className="publisher-name float-left text-muted text-truncate">
                    {getPublisherName(article)}
                </span>
                <span onClick={openEditModal} className="mr-2">
                    <i className="far fa-edit pointer"></i>
                </span>
                <CopyToClipboard
                    className="float-right pointer"
                    text={`${article ? article.id : ""}`}
                    onCopy={() => {
                        alert.show("copied", { timeout: 1000, type: "info" });
                    }}>
                    <span>
                        <i className="far fa-copy"></i>
                    </span>
                </CopyToClipboard>
            </div>
            <div className="article-image-wrapper">
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                    <img className="article-image w-100 d-block align-top" src={article.images && article.images[0]} alt="article" />
                </a>
            </div>
            <div style={{ height: "auto" }} className="article-title card-body py-2">
                <p className="text-uppercase">{article.subcategory || <span className="text-danger">[SUBTITLE NEEDED]</span>}</p>
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                    <p className="card-text text-primary">{article.headline}</p>
                </a>
            </div>
            <div className="article-footer card-footer border-top-0 text-muted py-1">
                <small>Added {toTimeAgo(article.createdAt)}</small>
                <ConfirmPopup title="Unpin this article?" onConfirm={onDeleteClick}>
                    <span className="float-right pointer">
                        <i className="fas fa-trash-alt"></i>
                    </span>
                </ConfirmPopup>
            </div>
            {isEditMode && (
                <AddOrUpdateSpecialContentModal
                    tag={tag}
                    topicId={topicId}
                    content={article}
                    type={"edit"}
                    onClose={onModalClose}
                    onSuccess={onUpdateSuccess}
                />
            )}
        </div>
    );
}

ArticleTile.propTypes = {
    article: PropTypes.object.isRequired,
    topicId: PropTypes.string.isRequired,
    deleteArticle: PropTypes.func,
    askParentToRefetch: PropTypes.func.isRequired,
    tag: PropTypes.oneOf(["fun", "censored"]).isRequired
};

export default ArticleTile;
