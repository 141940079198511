// Note: react-scripts require env variables to be prefixed with REACT_APP

// Required config
export const ENV                =       process.env.REACT_APP_ENV               ||  'not set';
export const BACKEND_URL        =       process.env.REACT_APP_BACKEND_URL;
export const OPENID_ISSUER      =       process.env.REACT_APP_OPENID_ISSUER;
export const OPENID_REALM       =       process.env.REACT_APP_OPENID_REALM;
export const OPENID_CLIENT_ID   =       process.env.REACT_APP_OPENID_CLIENT_ID;

// App Analytics (optional)
export const ENABLE_DATADOG     =       process.env.REACT_APP_ENABLE_DATADOG    === "true";
export const DATADOG_ENV        =       process.env.REACT_APP_DATADOG_ENV       ||  'not set';

// Proxy (used for fetching URL html data when pinning articles, also for twitter reports)
export const PROXY_URL          =       process.env.REACT_APP_PROXY_URL         ||  'proxy url is not set';
export const PROXY_AUTH_KEY     =       process.env.REACT_APP_PROXY_AUTH_KEY    ||  'proxy auth key is not set';

export const APP_VERSION        =       process.env.REACT_APP_VERSION           ||  'unknown';
export const COMMIT_HASH        =       process.env.REACT_APP_COMMIT_HASH       ||  'unknown';
