import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useAlert } from "react-alert";
import TextareaAutosize from "react-textarea-autosize";
import { useAuth } from "react-oidc-context";
import { nanoid } from "nanoid";
import { getSimilarityListAPI } from "../../api/similarityAPI";
import { getStoryByIdAPI } from "../../api/storyAPI";
import { arrayMove } from "../../utils/utils";
import ConfirmPopup from "../utils/confirmPopup";

const SortableContentContainer = SortableContainer(({ children }) => <tbody>{children}</tbody>);

function SimilarityTab({ storyId, articles, similarity, onSave }) {
    const alert = useAlert();
    const { user } = useAuth();

    const [isLoading, setLoading] = useState(false);
    const [summaryData, setSummaryData] = useState(() => similarity || { left: [], middle: [], right: [] });
    const [minSentenceSimilarity, setMinSentenceSimilarity] = useState(0.5);
    const [maxSentencesByCluster, setMaxSentencesByCluster] = useState(0);
    const [selectedBias, setSelectedBias] = useState("left");

    const pinnedArticleByBias = useMemo(() => {
        if (!articles.length) {
            return { left: [], middle: [], right: [] };
        }
        const leftArticles = articles.filter(item => item.full_info.bias === "left");
        const middleArticles = articles.filter(item => item.full_info.bias === "middle");
        const rightArticles = articles.filter(item => item.full_info.bias === "right");

        return { left: leftArticles, middle: middleArticles, right: rightArticles };
    }, [articles]);

    // This fetches existing similarities that are linked to this story
    const fetchStoryLinkedSimilarities = useCallback(() => {
        setLoading(true);
        getStoryByIdAPI(storyId, user.access_token)
            .then(data => {
                if (data && data.data && data.data.similarity) {
                    setSummaryData(data.data.similarity);
                }
            })
            .catch(e => alert.error(e))
            .finally(() => setLoading(false));
    }, [alert, user.access_token, storyId]);

    useEffect(() => {
        fetchStoryLinkedSimilarities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onGetSummaryBtnClick = useCallback(() => {
        if (!articles || !articles.length) {
            alert.error("ERROR: The story must have at least 1 pinned article per bias");
            return;
        }

        const articlesList = articles
            .filter(article => article.full_info.bias === selectedBias)
            .map(item => ({
                id: item.id
            }));

        if (!articlesList.length) {
            alert.error(`ERROR: The selected <${selectedBias}> bias doesn't have any pinned articles`);
            return;
        }

        setLoading(true);

        // we store selectedBias withing this async context, because by the time the API responds user could switch to another bias
        const requestSimilarityBias = selectedBias;

        getSimilarityListAPI(user.access_token, articlesList, { minSentenceSimilarity, maxSentencesByCluster })
            .then(data => {
                if (data && data.similaritySentencesList && data.similaritySentencesList.length) {
                    setSummaryData(currentData => {
                        // do not delete items created by curator via "+" button
                        const customItems = currentData[requestSimilarityBias].filter(item => item.$isCustom);
                        return {
                            ...currentData,
                            [requestSimilarityBias]: [
                                ...customItems,
                                ...data.similaritySentencesList.map(item => ({
                                    id: nanoid(),
                                    associated_item_id: item.article?.id,
                                    source_name: getPublisherNameFromItem(item.article),
                                    source_url: item.article?.url,
                                    sentence: item.sentence
                                }))
                            ]
                        };
                    });
                } else {
                    setSummaryData({ left: [], middle: [], right: [] });
                }
            })
            .catch(e => alert.show(e))
            .finally(() => setLoading(false));
    }, [alert, articles, user.access_token, maxSentencesByCluster, minSentenceSimilarity, selectedBias]);

    const onSaveBtnClick = useCallback(() => {
        onSave(summaryData);
    }, [onSave, summaryData]);

    const onDeleteBtnClick = useCallback(
        itemId => {
            return e => {
                e.preventDefault();
                setSummaryData(currentSummaryData => {
                    if (currentSummaryData && currentSummaryData[selectedBias] && currentSummaryData[selectedBias].length) {
                        const summaryDataFiltered = currentSummaryData[selectedBias].filter(item => item.id !== itemId); // remove item
                        setSummaryData({ ...currentSummaryData, [selectedBias]: summaryDataFiltered });
                    }
                });
            };
        },
        [selectedBias]
    );

    const addEmptySummary = useCallback(() => {
        const newSummaryData = {
            id: nanoid(),
            associated_item_id: "",
            source_name: "",
            source_url: "",
            sentence: "",
            $isCustom: true
        };

        const currentSelectedSummaryData = summaryData[selectedBias];
        currentSelectedSummaryData.unshift(newSummaryData);

        setSummaryData({ ...summaryData, [selectedBias]: [...currentSelectedSummaryData] });
    }, [selectedBias, summaryData]);

    const onInputChange = useCallback(
        (type, itemIndex) => {
            return e => {
                if (type === "minSentenceSimilarity") {
                    setMinSentenceSimilarity(parseFloat(e.target.value));
                } else if (type === "maxSentencesByCluster") {
                    setMaxSentencesByCluster(parseInt(e.target.value));
                } else if (type === "similarity") {
                    // you need to destruct this data here to prevent "This synthetic event is reused for performance reasons" error. Another options would be to call e.persist()
                    const { name, value } = e.target;

                    // we pass inline function to setSummaryData because we don't want to add 'summaryData' to useCallback dependencies
                    // because it would cause remount on child inputs every time user changes input data, which also causes input focus loss.
                    setSummaryData(currentSummaryData => {
                        if (currentSummaryData && currentSummaryData[selectedBias] && currentSummaryData[selectedBias][itemIndex]) {
                            const newSummaryData = currentSummaryData[selectedBias];
                            newSummaryData[itemIndex][name] = value;
                            return { ...currentSummaryData, [selectedBias]: [...newSummaryData] };
                        }
                    });
                } else if (type === "bias") {
                    setSelectedBias(e.target.name);
                } else if (type === "select-publisher") {
                    // "value" in this case is the index of pinned article in "pinnedArticleByBias" object
                    // you need to destruct this data here to prevent "This synthetic event is reused for performance reasons" error. Another options would be to call e.persist()
                    const { value } = e.target;
                    setSummaryData(currentSummaryData => {
                        if (currentSummaryData && currentSummaryData[selectedBias] && currentSummaryData[selectedBias][itemIndex]) {
                            const newSummaryData = currentSummaryData[selectedBias];
                            const pinnedArticle = pinnedArticleByBias[selectedBias][parseInt(value)];
                            newSummaryData[itemIndex].source_name = pinnedArticle.full_info.publisherName;
                            newSummaryData[itemIndex].source_url = pinnedArticle.full_info.url;
                            return { ...currentSummaryData, [selectedBias]: [...newSummaryData] };
                        }
                    });
                }
            };
        },
        [pinnedArticleByBias, selectedBias]
    );

    const onDataPositionUpdate = useCallback(
        (oldIndex, newIndex) => {
            const repositionedSummaryData = arrayMove(summaryData[selectedBias], oldIndex, newIndex);
            setSummaryData({ ...summaryData, [selectedBias]: repositionedSummaryData });
        },
        [selectedBias, summaryData]
    );

    const summaryDataFilteredByBias = useMemo(() => {
        if (summaryData && summaryData[selectedBias]) {
            return summaryData[selectedBias];
        }
    }, [selectedBias, summaryData]);

    return (
        <div className="mt-2 bg-light p-2 rounded">
            <div className="form-group my-0">
                <fieldset className="form-group d-flex align-center my-2">
                    <label htmlFor="minSentenceSimilarity" className="form-label my-0 w-100 text-info">
                        Similarity Precision ({minSentenceSimilarity})
                    </label>
                    <input
                        onChange={onInputChange("minSentenceSimilarity")}
                        defaultValue={minSentenceSimilarity}
                        type="range"
                        className="form-range"
                        min="0"
                        max="1"
                        step="0.1"
                        id="minSentenceSimilarity"
                    />
                </fieldset>
            </div>
            {/* <div className="form-group my-0">
                <fieldset className="form-group d-flex align-center my-2">
                    <label htmlFor="minSentenceSimilarity" className="form-label my-0 w-100 text-info">
                        Max Sentences by Cluster ({maxSentencesByCluster === 0 ? "Unspecified" : maxSentencesByCluster})
                    </label>
                    <input
                        onChange={onInputChange("maxSentencesByCluster")}
                        defaultValue={maxSentencesByCluster}
                        type="range"
                        className="form-range"
                        min="0"
                        max="20"
                        step="1"
                        id="maxSentencesByCluster"
                    />
                </fieldset>
            </div> */}
            <div className="w-100 d-flex justify-content-between">
                <fieldset className="form-group mb-0 d-flex align-items-center">
                    <div className="form-check d-inline mr-2">
                        <label className="form-check-label">
                            <input
                                onChange={onInputChange("bias")}
                                type="radio"
                                className="form-check-input"
                                name="left"
                                value="left"
                                checked={selectedBias === "left"}
                            />
                            Left
                        </label>
                    </div>
                    <div className="form-check d-inline mr-2">
                        <label className="form-check-label">
                            <input
                                onChange={onInputChange("bias")}
                                type="radio"
                                className="form-check-input"
                                name="middle"
                                value="middle"
                                checked={selectedBias === "middle"}
                            />
                            Middle
                        </label>
                    </div>
                    <div className="form-check d-inline">
                        <label className="form-check-label">
                            <input
                                onChange={onInputChange("bias")}
                                type="radio"
                                className="form-check-input"
                                name="right"
                                value="right"
                                checked={selectedBias === "right"}
                            />
                            Right
                        </label>
                    </div>
                </fieldset>
                <button onClick={onGetSummaryBtnClick} className="btn btn-outline-primary btn-sm">
                    {!isLoading ? (
                        "Generate Summary"
                    ) : (
                        <div className="mx-5 spinner-border d-inline-block text-center spinner-grow-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                </button>
            </div>
            <div className="text-center">
                <span onClick={addEmptySummary}>
                    <i className="fas fa-plus text-secondary pointer"></i>
                </span>
            </div>
            {summaryDataFilteredByBias && summaryDataFilteredByBias.length > 0 && (
                <>
                    <SummaryTable
                        data={summaryDataFilteredByBias}
                        onDataPositionUpdate={onDataPositionUpdate}
                        onInputChange={onInputChange}
                        onDeleteBtnClick={onDeleteBtnClick}
                        pinnedArticleByBias={pinnedArticleByBias}
                        bias={selectedBias}
                    />
                    <div className="w-50 mt-3 mb-2 float-right">
                        <ConfirmPopup confirmText="Confirm" title={"Are you sure?"} maxWidth={400} onConfirm={onSaveBtnClick}>
                            <button className="btn btn-success float-right">Save</button>
                        </ConfirmPopup>
                    </div>
                </>
            )}
        </div>
    );
}

const SummaryTable = ({ data, onDataPositionUpdate, onInputChange, onDeleteBtnClick, pinnedArticleByBias, bias }) => {
    const onSortEnd = useCallback(
        ({ newIndex, oldIndex }) => {
            // sanity check: position has not been changed
            if (oldIndex === newIndex) return;

            // call parent to update item position
            onDataPositionUpdate(oldIndex, newIndex);
        },
        [onDataPositionUpdate]
    );

    const populateContent = useCallback(
        props => (
            <TableSummaryItem
                key={props.id}
                {...props}
                onInputChange={onInputChange}
                onDeleteBtnClick={onDeleteBtnClick}
                pinnedArticleByBias={pinnedArticleByBias}
                bias={bias}
            />
        ),
        [bias, onDeleteBtnClick, onInputChange, pinnedArticleByBias]
    );

    const SortableItem = useMemo(() => SortableElement(populateContent), [populateContent]);

    const SortableItemList = useMemo(() => {
        return data.map((item, i) => <SortableItem key={item.id} itemIndex={i} index={i} {...item} />);
    }, [data]);

    return (
        <table className="table table-active mt-2">
            <thead>
                <tr>
                    <th scope="col" className="text-left">
                        #
                    </th>
                    <th scope="col" className="text-left">
                        Summary
                    </th>
                    <th scope="col" className="text-left">
                        Publisher
                    </th>
                    <th scope="col" className="text-right">
                        Action
                    </th>
                </tr>
            </thead>
            {Array.isArray(data) && (
                <SortableContentContainer key="sortable-similarity-item" axis={"y"} disableAutoscroll={true} onSortEnd={onSortEnd}>
                    {SortableItemList}
                </SortableContentContainer>
            )}
        </table>
    );
};

const TableSummaryItem = React.memo(
    ({ id, source_url, sentence, source_name, itemIndex, onInputChange, onDeleteBtnClick, $isCustom, pinnedArticleByBias, bias }) => {
        const [isCustomSelect, setIsCustomSelect] = useState(false);

        const onSelect = useCallback(
            e => {
                if (e.target.value !== "custom") {
                    const onInputChangeCall = onInputChange("select-publisher", itemIndex);
                    setIsCustomSelect(false);
                    onInputChangeCall(e);
                } else {
                    setIsCustomSelect(true);
                }
            },
            [itemIndex, onInputChange]
        );

        return (
            <tr className="table-light" key={id}>
                <th>
                    <a className="text-info" target="_blank" rel="noreferrer noopener" href={source_url}>
                        <span>{itemIndex + 1}</span>
                    </a>
                </th>
                <td>
                    <TextareaAutosize className="form-control" value={sentence} onChange={onInputChange("similarity", itemIndex)} name="sentence" />
                </td>
                <td>
                    {$isCustom && !isCustomSelect && !source_name ? (
                        <select onChange={onSelect} defaultValue={""} name="bias" id="bias" className="custom-select d-inline">
                            <option value="">Select one</option>
                            <option key={"custom"} value={"custom"}>
                                Custom
                            </option>
                            {Array.isArray(pinnedArticleByBias && pinnedArticleByBias[bias]) &&
                                pinnedArticleByBias[bias].map((item, i) => (
                                    <option key={i} value={i}>
                                        {item.full_info.publisherName}
                                    </option>
                                ))}
                        </select>
                    ) : (
                        <TextareaAutosize
                            className="form-control"
                            value={source_name}
                            onChange={onInputChange("similarity", itemIndex)}
                            name="source_name"
                        />
                    )}
                </td>
                <td>
                    <button onClick={onDeleteBtnClick(id)} className="btn btn-link text-info float-right px-0">
                        Delete
                    </button>
                </td>
            </tr>
        );
    }
);

function getPublisherNameFromItem(item) {
    if (item && Array.isArray(item.publishersList)) {
        const firstPublisher = item.publishersList.find(publisher => !!publisher.name);
        if (firstPublisher) {
            return firstPublisher.name;
        }
    }
}
SimilarityTab.propTypes = {
    storyId: PropTypes.string.isRequired,
    articles: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    similarity: PropTypes.object
};

export default SimilarityTab;
