import { format, formatDistanceToNowStrict } from "date-fns";
import { cloneDeep, isEqual } from "lodash-es";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import ReactDOM from "react-dom";
import { useAuth } from "react-oidc-context";
import API from "../../api/deepDiveAPI";
import { getImageSourceList } from "../../utils/utils.js";
import ConfirmPopup from "../utils/confirmPopup";
import DatePicker from "../utils/datePicker";
import AISummaryTab from "./aiSummaryTab/aiSummaryTab";
import BannedContentTab from "./bannedContentTab";
import PinnedContentTab from "./pinnedContentTab";
import PinnedDocumentsTab from "./pinnedDocumentsTab/pinnedDocumentsTab";
import SimilarityTab from "./similarityTab";
import TopicPreview from "./topicPreview.js";
import VideoContentTab from "./videoContentTab";
import PinnedVideoTab from "./videos/pinnedVideosTab.js";
import { safeParseJson } from "../utils/parse";
import "./topicPreview.css";

function Topic(props) {
    const { user } = useAuth();
    const alert = useAlert();
    const isVideoStory = props.categoryName === "Freespoke video";
    const createdAt = useMemo(() => format(new Date(props.topic.createdAt), "MMM d, yyyy (h:mm b)"), [props.topic.createdAt]);
    const createdAtShortFormat = useMemo(() => format(new Date(props.topic.createdAt), "M/L/yyyy"), [props.topic.createdAt]);
    const resultStartDate = useMemo(
        () => props.topic.result_start_date && format(new Date(props.topic.result_start_date), "MMM d"),
        [props.topic.result_start_date]
    );
    const resultEndDate = useMemo(
        () => props.topic.result_end_date && format(new Date(props.topic.result_end_date), "MMM d"),
        [props.topic.result_end_date]
    );

    const getDynamicTopicImage = useCallback(() => {
        const pinned = props.topic.pinnedArticles;
        // sortByDate(pinned, "datePublished");
        return pinned && pinned[0] && getImageSourceList(pinned[0])[0];
    }, [props.topic.pinnedArticles]);

    const [isEditMode, setEditMode] = useState(false);
    const [relinkId, setRelinkId] = useState(props.categoryId);
    const [isSaving, setIsSaving] = useState(false);
    const [story, setStory] = useState(cloneDeep(props.topic));
    const [selectedSubMenu, setSelectedSubMenu] = useState(isVideoStory ? "video-content" : "pinned-content");
    const [topicImg, setTopicImg] = useState(() => {
        if (story.image_url) {
            return story.image_url;
        }
        return getDynamicTopicImage();
    });

    useEffect(() => {
        setStory(cloneDeep(props.topic));
        setIsSaving(false);
    }, [props.topic, props.topic.updatedAt]);

    // useEffect(() => {
    //     saveChanges();
    // }, [story.query, story.tags]);

    useEffect(() => {
        if (!props.topic.image_url) {
            setTopicImg(getDynamicTopicImage());
        } else {
            setTopicImg(story.image_url);
        }
    }, [getDynamicTopicImage, props.topic.image_url, story.image_url]);

    const toggleEditMode = () => {
        setEditMode(!isEditMode);
    };

    const cancelRelink = () => {
        setRelinkId(props.categoryId);
    };

    const onRelinkSelect = e => {
        setRelinkId(e.target.value);
    };

    const onChange = e => {
        setStory({ ...story, [e.target.name]: e.target.value });
    };

    const setTopicImage = url => {
        props
            .updateTopic(props.topic.id, { image_url: url })
            .then(() => alert.show("Done", { timeout: 1000, type: "info" }))
            .catch(err => alert.show(`Error: ${err.message}`, { timeout: 1000, type: "error" }));
    };

    const removeFixedTopicImage = () => {
        props
            .updateTopic(props.topic.id, { image_url: "" })
            .then(() => alert.show("Done", { timeout: 1000, type: "info" }))
            .catch(err => alert.show(`Error: ${err.message}`, { timeout: 1000, type: "error" }));
    };

    const saveChanges = () => {
        if (!hasChanges) {
            console.log("No changes");
            setEditMode(false);
            return;
        }

        setIsSaving(true);
        setEditMode(false);

        console.log("Found changes");
        ReactDOM.unstable_batchedUpdates(() => {
            const fieldsToUpdate = {
                ...(story.name !== props.topic.name && { name: story.name }),
                // ...(story.summary !== props.topic.summary && { summary: story.summary }),
                ...(story.link_to_spangld !== props.topic.link_to_spangld && { link_to_spangld: story.link_to_spangld }),
                ...(story.query !== props.topic.query && { query: story.query }),
                ...(story.result_start_date !== props.topic.result_start_date && { result_start_date: story.result_start_date }),
                ...(story.result_end_date !== props.topic.result_end_date && { result_end_date: story.result_end_date }),
                tags: story.tags
            };
            props
                .updateTopic(props.topic.id, fieldsToUpdate)
                .then(() => alert.show("saved", { timeout: 1000, type: "info" }))
                .catch(() => alert.show("Saving Error", { timeout: 1000, type: "error" }))
                .finally(() => setIsSaving(false));
        });
    };

    const saveSimilarity = similarity => {
        setIsSaving(true);
        setEditMode(false);

        props
            .updateTopic(props.topic.id, { similarity })
            .then(() => alert.show("saved", { timeout: 1000, type: "info" }))
            .catch(() => alert.show("Saving Error", { timeout: 1000, type: "error" }))
            .finally(() => setIsSaving(false));
    };

    // const onTopicQuerySave = query => {
    //     const updatedTopic = cloneDeep(story);
    //     updatedTopic.query = query;
    //     setStory(updatedTopic);
    // };

    const updatePinnedArticle = async (articleId, data) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setIsSaving(true);
            setEditMode(false);
            props.updatePinnedArticle(props.topic.id, articleId, data).finally(() => setIsSaving(false));
        });
    };

    const updatePinnedTweet = async (tweetId, data) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setIsSaving(true);
            setEditMode(false);
            props.updatePinnedTweet(tweetId, data).finally(() => setIsSaving(false));
        });
    };

    const resetTopic = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            setStory(cloneDeep(props.topic));
            setEditMode(!isEditMode);
        });
    };

    const deletePinnedArticle = id => {
        setIsSaving(true);
        props.unpinArticle(props.topic.id, id).finally(() => setIsSaving(false));
    };

    const deletePinnedTweet = id => {
        setIsSaving(true);
        props.unpinTweet(props.topic.id, id).finally(() => {
            setIsSaving(false);
        });
    };

    const moveTopic = async e => {
        const direction = e.currentTarget.getAttribute("data-direction");
        setIsSaving(true);
        props.changeTopicOrder(props.topic.id, direction).finally(() => setIsSaving(false));
    };

    const relinkStory = () => {
        setIsSaving(true);
        props
            .relinkStoryToCategory(props.topic.id, relinkId)
            .then(() => setIsSaving(false))
            .catch(err => {
                setIsSaving(false);
                alert.show(`Error: ${err.message}`, { timeout: 1000, type: "error" });
            });
    };

    const onActiveInputChange = async e => {
        if (e.target.type === "checkbox") {
            const fieldsToUpdate = {
                [e.target.name]: e.target.name === "disable_organic_results" ? !e.target.checked : e.target.checked
            };
            setIsSaving(true);
            props.updateTopic(props.topic.id, fieldsToUpdate).finally(() => setIsSaving(false));
        }
    };

    const importTopicImageFromPreview = img => {
        if (!props.topic.image_url) {
            console.log("img: ", img);
            setTopicImg(img);
        }
    };

    const hasUnsavedChanges = () => {
        if (story.name !== props.topic.name) {
            return true;
        } else if (story.summary !== props.topic.summary) {
            return true;
        } else if (story.link_to_spangld !== props.topic.link_to_spangld) {
            return true;
        } else if (!isEqual(story.tags, props.topic.tags)) {
            if (typeof story.tags === "undefined" && typeof props.topic.tags === "undefined") {
                return false;
            }
            return true;
        } else if (!isEqual(story.query, props.topic.query)) {
            if (typeof story.query === "undefined" && typeof props.topic.query === "undefined") {
                return false;
            }
            return true;
        } else if (story.result_start_date !== props.topic.result_start_date) {
            if (typeof story.result_start_date === "undefined" && typeof props.topic.result_start_date === "undefined") {
                return false;
            }
            return true;
        } else if (story.result_end_date !== props.topic.result_end_date) {
            if (typeof story.result_end_date === "undefined" && typeof props.topic.result_end_date === "undefined") {
                return false;
            }
            return true;
        } else {
            return false;
        }
    };

    const pinNewContent = newContent => {
        ReactDOM.unstable_batchedUpdates(() => {
            // Edge case when curator passes a raw json that represents tweet in the URL field
            const tweetAsJson = safeParseJson(newContent.url);

            if (tweetAsJson) {
                setIsSaving(true);
                props.pinTweet(props.topic.id, { tweet: tweetAsJson }).finally(() => {
                    setIsSaving(false);
                });
            } else if (newContent.tweet_id && newContent.url) {
                setIsSaving(true);
                props.pinTweet(props.topic.id, newContent).finally(() => {
                    setIsSaving(false);
                });
            } else {
                setIsSaving(true);
                props.pinArticle(props.topic.id, newContent).finally(() => {
                    setIsSaving(false);
                });
            }
        });
    };

    const hasChanges = hasUnsavedChanges();

    let pinnedContent = props.topic.pinnedContent || [];

    pinnedContent = pinnedContent.map(content => {
        if (content.tweet_id) {
            return content;
        }
        return {
            id: content.id,
            article_id: content.id,
            full_info: { ...content }
        };
    });

    const isSatire = props.categoryName === "Make me laugh";

    const categorySelectBox = (
        <div style={{ minWidth: "250px", padding: "0 0 12px 0" }}>
            <select onChange={onRelinkSelect} id="categoryLinkSelect" value={relinkId} className="custom-select d-inline">
                {props.categories.map(category => {
                    return (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    const unlinkToElectionType = () => {
        setIsSaving(true);
        API.unLinkRelatedStoryOrTopicToTopic("T43aCWOxTwf5_OJWzRcAY", props.topic.id, "story", user.access_token)
            .then(() => props.refetchStories())
            .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }))
            .finally(() => setIsSaving(false));
    };

    const linkToElectionType = () => {
        setIsSaving(true);
        API.linkRelatedStoryOrTopicToTopic("T43aCWOxTwf5_OJWzRcAY", props.topic.id, "story", user.access_token)
            .then(() => props.refetchStories())
            .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }))
            .finally(() => setIsSaving(false));
    };

    const onElectionStoryToggle = e => {
        if (e.target.checked) {
            linkToElectionType();
        } else {
            unlinkToElectionType();
        }
    };

    const renderWarnings = useMemo(() => getStoryWarnings(story), [story]);

    return (
        <div className="topic" data-cid="StoryContentTopic">
            {isSaving ? (
                <div className="loading-modal" data-testid="loading-modal">
                    <div className="spinner spinner-border float-right d-inline-block mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}
            <div className={`card mb-2 ${!story.isActive ? "border border-danger" : ""}`}>
                <div className="card-body">
                    <div className="mb-2">
                        {!isEditMode ? (
                            <div className="container">
                                <div className="row">
                                    {/* TOPIC IMAGE */}
                                    <div className="topic-img-div col-2 p-0">
                                        {topicImg ? (
                                            <React.Fragment>
                                                <div style={{ backgroundImage: `url(${topicImg})` }} className="topic-img" />
                                                {story.image_url ? (
                                                    <p>
                                                        fixed image{" "}
                                                        <i onClick={removeFixedTopicImage} className="far fa-times-circle text-danger"></i>
                                                    </p>
                                                ) : (
                                                    <p>dynamic image </p>
                                                )}
                                            </React.Fragment>
                                        ) : typeof topicImg === "undefined" ? (
                                            <React.Fragment>
                                                <div className="topic-no-img">
                                                    <span style={{ cursor: "pointer" }} onClick={() => setSelectedSubMenu("preview")}>
                                                        Click to preview
                                                    </span>
                                                </div>
                                                {story.image_url && <p>dynamic image</p>}
                                            </React.Fragment>
                                        ) : (
                                            <div className="topic-no-img">No image</div>
                                        )}
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            {/* TOPIC TITLE */}
                                            <div className="col-8 p-0 pl-3">
                                                <h4 className="card-title d-inline" data-testid="content-story-name">
                                                    {story.name}
                                                </h4>
                                            </div>
                                            {/* TOPIC CONTROLS */}
                                            <div className="col-4 p-0 ml-auto">
                                                <div className="custom-control custom-switch d-inline-block float-right ml-2">
                                                    <input
                                                        onChange={onActiveInputChange}
                                                        checked={story.isActive}
                                                        type="checkbox"
                                                        id={props.topic.id}
                                                        name="isActive"
                                                        className="custom-control-input"
                                                    ></input>
                                                    <label
                                                        className="custom-control-label pointer"
                                                        data-testid="story-label-toggle"
                                                        htmlFor={props.topic.id}
                                                    >
                                                        {story.isActive ? "On" : "Off"}
                                                    </label>
                                                </div>
                                                <span onClick={toggleEditMode} className="align-middle float-right pointer ml-2">
                                                    <i className="far fa-edit" data-testid="edit-story-title"></i>
                                                </span>
                                                {!props.isLastTopic ? (
                                                    <span
                                                        onClick={moveTopic}
                                                        data-direction="down"
                                                        className="align-middle float-right pointer ml-2 mr-2"
                                                    >
                                                        <i className="fas fa-arrow-down"></i>
                                                    </span>
                                                ) : null}
                                                {!props.isFirstTopic ? (
                                                    <span
                                                        onClick={moveTopic}
                                                        data-direction="up"
                                                        className="align-middle float-right pointer ml-2 mr-2"
                                                    >
                                                        <i className="fas fa-arrow-up"></i>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* TOPIC SUMMARY */}
                                        {/* {story.summary && !isEditMode ? <p className="card-subtitle text-muted my-2">{story.summary}</p> : null} */}
                                    </div>

                                    {!isVideoStory && renderWarnings}

                                    <div className={`custom-control custom-switch float-right pt-2 mb-1 mt-2`}>
                                        <input
                                            onChange={onActiveInputChange}
                                            checked={!story.disable_organic_results}
                                            id={`org_res-${props.topic.id}`}
                                            type="checkbox"
                                            name="disable_organic_results"
                                            className={`custom-control-input`}
                                        ></input>
                                        <label
                                            className={`custom-control-label pointer ${!story.disable_organic_results ? "enabled" : ""}`}
                                            data-testid="enable-organic-results"
                                            htmlFor={`org_res-${props.topic.id}`}
                                        >
                                            Enable organic results
                                        </label>
                                    </div>

                                    <div className={`custom-control custom-switch float-right pt-2 mb-1 w-100`}>
                                        <input
                                            onChange={onActiveInputChange}
                                            checked={story.similarity_enabled}
                                            id={`similarity_enabled-${props.topic.id}`}
                                            type="checkbox"
                                            name="similarity_enabled"
                                            className={`custom-control-input`}
                                        ></input>
                                        <label
                                            className={`custom-control-label pointer ${story.similarity_enabled ? "enabled" : ""}`}
                                            data-testid="enable-similarity"
                                            htmlFor={`similarity_enabled-${props.topic.id}`}
                                        >
                                            Enable similarity
                                        </label>
                                    </div>

                                    <div className={`custom-control custom-switch float-right pt-2 mb-1 w-100`}>
                                        <input
                                            onChange={onElectionStoryToggle}
                                            checked={story.labels && story.labels.is_election_story === "true"}
                                            id={`election-story-enabled-${props.topic.id}`}
                                            type="checkbox"
                                            name="similarity_enabled"
                                            className={`custom-control-input`}
                                        ></input>
                                        <label
                                            className={`custom-control-label pointer ${
                                                story.labels && story.labels.is_election_story === "true" ? "enabled" : ""
                                            }`}
                                            htmlFor={`election-story-enabled-${props.topic.id}`}
                                            data-testid="election-story"
                                        >
                                            Election Story
                                        </label>
                                    </div>

                                    {/* {!isVideoStory && (
                                        <TopicQuery topic={story} onSave={onTopicQuerySave} onActiveInputChange={onActiveInputChange} />
                                    )} */}
                                </div>
                            </div>
                        ) : null}
                        {isEditMode ? (
                            <div key="new" className="input-group mb-3">
                                <label className="form-control-label w-100 font-weight-bold" htmlFor="name">
                                    Name:
                                </label>
                                <textarea
                                    className="form-control"
                                    onChange={onChange}
                                    value={story.name}
                                    id="name"
                                    name="name"
                                    rows="2"
                                    placeholder="Add a story name"
                                    data-testid="name-input"
                                ></textarea>
                            </div>
                        ) : null}
                    </div>
                    {isEditMode ? (
                        <div className="form-group">
                            {/* <label className="font-weight-bold" htmlFor="summary">
                                Summary:
                            </label>
                            <textarea
                                className="form-control"
                                onChange={onChange}
                                value={story.summary}
                                id="summary"
                                name="summary"
                                rows="3"></textarea> */}

                            <label className="font-weight-bold mt-3" htmlFor="link_to_spangld">
                                Spangld article URL:
                            </label>
                            <input
                                className="form-control"
                                onChange={onChange}
                                value={story.link_to_spangld}
                                type="text"
                                id="link_to_spangld"
                                name="link_to_spangld"
                                placeholder="https://spangld.com/..."
                            ></input>

                            <label className="font-weight-bold mt-3" htmlFor="link_to_spangld">
                                Custom query:
                            </label>
                            <input
                                className="form-control"
                                onChange={onChange}
                                value={story.query}
                                type="text"
                                id="query"
                                name="query"
                                // placeholder="type search term"
                            ></input>

                            <p className="font-weight-bold form-control-label mt-3 mb-2">
                                Limit organic results by a date range (note: story created on {createdAtShortFormat})
                            </p>

                            <div className="d-flex">
                                <div className="d-inline-block">
                                    <label className="form-control-label mr-2">From:</label>
                                    <DatePicker
                                        className="form-control bg-white pl-2"
                                        isClearable
                                        todayButton="Now"
                                        selected={story.result_start_date ? new Date(story.result_start_date) : null}
                                        onChange={newStartDate =>
                                            onChange({ target: { name: "result_start_date", value: newStartDate && newStartDate.toISOString() } })
                                        }
                                        placeholderText="Click to select a date"
                                    />
                                </div>

                                <div className="d-inline-block ml-auto">
                                    <label className="form-control-label mr-2">To:</label>
                                    <DatePicker
                                        className="form-control bg-white pl-2"
                                        isClearable
                                        todayButton="Now"
                                        selected={story.result_end_date ? new Date(story.result_end_date) : null}
                                        onChange={newEndDate =>
                                            onChange({ target: { name: "result_end_date", value: newEndDate && newEndDate.toISOString() } })
                                        }
                                        placeholderText="Click to select a date"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {story.link_to_spangld && !isEditMode ? (
                        <a href={story.link_to_spangld} className="d-block card-subtitle text-primary mb-2" target="_blank" rel="noopener noreferrer">
                            <span className="badge badge-primary">SPANGLD:</span>
                            <span className="ml-2 text-info">{story.link_to_spangld}</span>
                        </a>
                    ) : null}
                    {!isEditMode && (
                        <div className="form-group">
                            <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                                {!isVideoStory && (
                                    <label
                                        onClick={() => setSelectedSubMenu("pinned-content")}
                                        className={`control-label pointer ${
                                            selectedSubMenu === "pinned-content" ? "text-primary underline" : "text-secondary"
                                        }`}
                                        data-testid="content-tab"
                                    >
                                        Content
                                        <span className="badge badge-light badge-pill ml-1">{pinnedContent ? pinnedContent.length : "0"}</span>
                                    </label>
                                )}
                                {!isVideoStory && (
                                    <label
                                        onClick={() => setSelectedSubMenu("pinned-video")}
                                        className={`control-label pointer ${
                                            selectedSubMenu === "pinned-video" ? "text-primary underline" : "text-secondary"
                                        }`}
                                        data-testid="video-tab"
                                    >
                                        Videos
                                        {/* <span className="badge badge-light badge-pill ml-1">{pinnedContent ? pinnedContent.length : "0"}</span> */}
                                    </label>
                                )}

                                {isVideoStory && (
                                    <label
                                        onClick={() => setSelectedSubMenu("video-content")}
                                        className={`control-label pointer ${
                                            selectedSubMenu === "video-content" ? "text-primary underline" : "text-secondary"
                                        }`}
                                    >
                                        Video content
                                        <span className="badge badge-light badge-pill ml-1">
                                            {props.topic.videos ? props.topic.videos.length : "0"}
                                        </span>
                                    </label>
                                )}
                                {!isVideoStory && (
                                    <label
                                        onClick={() => setSelectedSubMenu("banned-content-button")}
                                        className={`control-label pointer ${
                                            selectedSubMenu === "banned-content" ? "text-primary underline" : "text-secondary"
                                        }`}
                                        data-testid="banned-tab"
                                    >
                                        Banned
                                        <span className="badge badge-light badge-pill ml-1">
                                            {story.bannedArticles ? story.bannedArticles.length : "0"}
                                        </span>
                                    </label>
                                )}
                                {!isVideoStory && (
                                    <label
                                        onClick={() => setSelectedSubMenu("similarity")}
                                        className={`control-label pointer ${
                                            selectedSubMenu === "similarity" ? "text-primary underline" : "text-secondary"
                                        }`}
                                    >
                                        Similarity
                                        {/* <span className="badge badge-light badge-pill ml-1">
                                            {story.similarity
                                                ? ((story.similarity.left && story.similarity.left.length) || 0) +
                                                  ((story.similarity.middle && story.similarity.middle.length) || 0) +
                                                  ((story.similarity.right && story.similarity.right.length) || 0)
                                                : "0"}
                                        </span> */}
                                    </label>
                                )}

                                {!isVideoStory && (
                                    <>
                                        <label
                                            onClick={() => setSelectedSubMenu("preview")}
                                            data-testid="preview-tab"
                                            className={`control-label pointer ${
                                                selectedSubMenu === "preview" ? "text-primary underline" : "text-secondary"
                                            }`}
                                        >
                                            Preview
                                        </label>
                                        <label
                                            onClick={() => setSelectedSubMenu("ai-summaries")}
                                            className={`control-label pointer ${
                                                selectedSubMenu === "ai-summaries" ? "text-primary underline" : "text-secondary"
                                            }`}
                                        >
                                            AI Summaries
                                            <span className="badge badge-light badge-pill ml-1">{props.topic?.ai_summaries?.length ?? 0}</span>
                                        </label>
                                        <label
                                            onClick={() => setSelectedSubMenu("pinned-documents")}
                                            className={`control-label pointer ${
                                                selectedSubMenu === "pinned-documents" ? "text-primary underline" : "text-secondary"
                                            }`}
                                            data-testid="documents-tab"
                                        >
                                            Documents
                                            <span className="badge badge-light badge-pill ml-1">{props.topic?.documents?.length ?? 0}</span>
                                        </label>
                                    </>
                                )}
                            </div>
                            <div className="form-group">
                                {selectedSubMenu === "pinned-content" && !isEditMode ? (
                                    <PinnedContentTab
                                        isSatire={props.categoryName === "Make me laugh"}
                                        storyId={props.topic.id}
                                        categoryName={props.categoryName}
                                        pinnedContent={pinnedContent}
                                        updatePinnedTweet={updatePinnedTweet}
                                        deletePinnedTweet={deletePinnedTweet}
                                        setTopicImage={setTopicImage}
                                        updatePinnedArticle={updatePinnedArticle}
                                        deletePinnedArticle={deletePinnedArticle}
                                        isEditMode={isEditMode}
                                        pinNewContent={pinNewContent}
                                        lastUpdated={props.topic.updatedAt}
                                        key={`pc-${pinnedContent.map(content => content.id)}`}
                                        refetchStories={props.refetchStories}
                                        lockParent={() => setIsSaving(true)}
                                    />
                                ) : null}
                                {selectedSubMenu === "pinned-video" && !isEditMode ? (
                                    <PinnedVideoTab
                                        storyId={props.topic.id}
                                        categoryName={props.categoryName}
                                        pinnedContent={pinnedContent}
                                        updatePinnedTweet={updatePinnedTweet}
                                        deletePinnedTweet={deletePinnedTweet}
                                        setTopicImage={setTopicImage}
                                        updatePinnedArticle={updatePinnedArticle}
                                        deletePinnedArticle={deletePinnedArticle}
                                        isEditMode={isEditMode}
                                        pinNewContent={pinNewContent}
                                        lastUpdated={props.topic.updatedAt}
                                        refreshParentData={props.refetchStories}
                                        lockParent={() => setIsSaving(true)}
                                    />
                                ) : null}
                                {selectedSubMenu === "video-content" && !isEditMode && !isSatire ? (
                                    <VideoContentTab categoryName={props.categoryName} pinnedVideos={props.topic.videos} storyId={props.topic.id} />
                                ) : null}
                                {selectedSubMenu === "banned-content" && !isEditMode && story.bannedArticles && story.bannedArticles.length > 0 && (
                                    <BannedContentTab
                                        storyId={props.topic.id}
                                        articleIDs={props.topic.bannedArticles}
                                        unBanArticle={props.removeArticleFromBanlist}
                                    />
                                )}
                                {selectedSubMenu === "similarity" && !isEditMode ? (
                                    <SimilarityTab
                                        storyId={props.topic.id}
                                        articles={pinnedContent.filter(item => !item.tweet_id)}
                                        similarity={props.topic.similarity}
                                        onSave={saveSimilarity}
                                    />
                                ) : null}
                                {selectedSubMenu === "preview" && !isEditMode ? (
                                    <TopicPreview
                                        key={props.topic.id}
                                        categoryName={props.categoryName}
                                        topic={story}
                                        pinnedContent={pinnedContent}
                                        topicLastUpdated={story.updatedAt}
                                        addArticleToBanlist={props.addArticleToBanlist}
                                        exportTopicImage={importTopicImageFromPreview}
                                        setTopicImage={setTopicImage}
                                        onPin={pinNewContent}
                                        onUpdateArticle={updatePinnedArticle}
                                        onUpdateTweet={updatePinnedTweet}
                                        onDeleteArticle={deletePinnedArticle}
                                        onDeleteTweet={deletePinnedTweet}
                                    />
                                ) : null}
                                {selectedSubMenu === "ai-summaries" && !isEditMode ? (
                                    <AISummaryTab
                                        key={props.topic.id}
                                        aiSummaries={props.topic.ai_summaries ?? []}
                                        storyId={props.topic.id}
                                        onRefetch={props.refetchStories}
                                    />
                                ) : null}
                                {selectedSubMenu === "pinned-documents" && !isEditMode ? (
                                    <PinnedDocumentsTab
                                        key={props.topic.id}
                                        documents={props.topic.documents}
                                        storyId={props.topic.id}
                                        onChangeDocuments={props.refetchStories}
                                    />
                                ) : null}
                            </div>
                        </div>
                    )}
                    {hasChanges && <span className="float-left text-muted">Unsaved changes</span>}
                    {!isEditMode && selectedSubMenu !== "similarity" && (
                        <div className="w-100 mt-3 mb-2">
                            <div className="w-100">
                                <span className="text-secondary">
                                    Created:
                                    <span className="float-right">{createdAt}</span>
                                </span>
                            </div>
                            <span className="d-block w-100 text-muted last-updated">
                                Last updated:
                                <span className="float-right">{formatDistanceToNowStrict(new Date(story.updatedAt), { addSuffix: true })}</span>
                            </span>
                            {(resultStartDate || resultEndDate) && (
                                <div className="w-100 text-secondary">
                                    <span className="d-block w-100">
                                        Organic articles date range limit:
                                        <span className="float-right">
                                            {resultStartDate || "no limit"} - {resultEndDate || "no limit"}
                                        </span>
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {!isEditMode && !isSaving && selectedSubMenu !== "similarity" ? (
                        <div className="w-50 mt-3 mb-2 float-right">
                            <ConfirmPopup
                                confirmText="Relink"
                                title={"Select new category"}
                                content={categorySelectBox}
                                maxWidth={400}
                                onConfirm={() => relinkStory()}
                                onCancel={() => cancelRelink()}
                            >
                                <span className="card-link float-right text-danger pointer">Relink</span>
                            </ConfirmPopup>
                        </div>
                    ) : null}
                    {isSaving ? (
                        <div style={{ width: "1.5em", height: "1.5em" }} className="spinner-border float-right d-inline-block mr-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : null}
                    {isEditMode && !isSaving && hasChanges ? (
                        <span onClick={saveChanges} className="card-link d-inline float-right ml-2 text-success pointer" data-testid="save-button">
                            Save
                        </span>
                    ) : null}
                    {isEditMode && !isSaving ? (
                        <span onClick={resetTopic} className="card-link d-inline float-right text-dark pointer">
                            Cancel
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

Topic.propTypes = {
    topic: PropTypes.object.isRequired,
    topicsTotal: PropTypes.number.isRequired,
    topicIndex: PropTypes.number.isRequired,
    isFirstTopic: PropTypes.bool.isRequired,
    isLastTopic: PropTypes.bool.isRequired,
    categoryName: PropTypes.string.isRequired,
    categoryType: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    setTopicImage: PropTypes.func,
    addArticleToBanlist: PropTypes.func.isRequired,
    removeArticleFromBanlist: PropTypes.func.isRequired,
    exportTopicImage: PropTypes.func,
    pinArticle: PropTypes.func.isRequired,
    pinTweet: PropTypes.func.isRequired,
    updateTopic: PropTypes.func.isRequired,
    unpinTweet: PropTypes.func.isRequired,
    unpinArticle: PropTypes.func.isRequired,
    changeTopicOrder: PropTypes.func.isRequired,
    updatePinnedTweet: PropTypes.func.isRequired,
    updatePinnedArticle: PropTypes.func.isRequired,
    relinkStoryToCategory: PropTypes.func.isRequired,
    refetchStories: PropTypes.func.isRequired,
    categories: PropTypes.array
};

export default React.memo(Topic);

function getStoryWarnings(story) {
    // Story in not active, curators is likely working on it - no need to pollute UI with warnings
    if (!story.isActive) {
        return null;
    }

    const warnings = [];

    // start gathering warnings for different edge cases
    if (!story.has_ai_summary) {
        warnings.push("Missing AI summary or it's not enabled");
    }

    if (story.has_ai_summary && !story?.ai_summaries?.length) {
        warnings.push("Unexpected error: AI summary feature is enabled but could not find any summaries");
    }

    if (!story.similarity_enabled) {
        warnings.push("Missing similarity or it's not enabled");
    }

    if (story.disable_organic_results) {
        warnings.push("Organic results are disabled");
    }

    if (!story.pinnedContent?.length) {
        warnings.push("Missing pinned articles");
    }

    if (story.pinnedContent?.length && !story.pinnedContent.filter(item => item.bias === "right")?.length) {
        warnings.push("Missing pinned articles with 'right' bias");
    }

    if (story.pinnedContent?.length && !story.pinnedContent.filter(item => item.bias === "middle" || item.bias === "center")?.length) {
        warnings.push("Missing pinned articles with 'middle' bias");
    }

    if (story.pinnedContent?.length && !story.pinnedContent.filter(item => item.bias === "left")?.length) {
        warnings.push("Missing pinned articles with 'left' bias");
    }

    if (!warnings?.length) {
        return null;
    }

    return (
        <div class="alert alert-dismissible alert-warning mt-2 mb-0 w-100">
            <h4 class="alert-heading">Warning!</h4>
            <ul>
                {warnings?.map(item => (
                    <li>{item}</li>
                ))}
            </ul>
        </div>
    );
}
